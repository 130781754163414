@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import "~antd/dist/antd.css";

/* ./styles/globals.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

*,
html,
body {
  font-family: "Rubik";
  scroll-behavior: smooth;
}
.glass {
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(2.5px);
  -webkit-backdrop-filter: blur(2.5px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
}
.bg {
  filter: brightness(50%);
}
.banner-user {
  height: 70vh;
}
.banner-user-elem {
  text-align: center;
  color: #fff;
  position: relative;
  overflow: hidden;
}
.banner-user-elem .banner-user-title {
  /* font-size: 32px; */
  /* top: 40%; */
}
.banner-user-elem .banner-user-text {
  /* top: 40%; */
}
.banner-anim-elem .bg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}
